import {
  ADD_INTERCEPTED_REQUEST,
  ADD_INTERCEPTED_VARIABLES,
  SET_INTERCEPTED_REQUESTS,
  SET_INTERCEPTORS,
  UPDATE_PROCESSED_REQUESTS,
} from '../action-creators';
import {
  APP__START_FULFILLED,
  AUTH_USER__LOGIN_FULFILLED,
  AUTH_USER__CAPTURE_FULLFILLED,
  AUTH_USER__REGISTER_FULFILLED,
  AUTH_USER__LOGOUT,
  CHECKOUT__PLACE_ORDER_GUEST,
  CHECKOUT__GET_PAYMENT_INTENT,
} from 'constants/actionTypes';
import { InterceptedRequestInitialState } from './initial-state';
import * as Interceptors from '../interceptors';

const updateVariables = (state, variables) => ({
  ...state,
  variablesByType: variables.reduce((acc, { type, key, value = null }) => {
    if (!acc[type]) acc[type] = {};
    acc[type][key] = value;
    return acc;
  }, state.variablesByType),
});

/**
 * Remove any redundant requests from the queue.
 * @param {*} requests
 * @returns
 */
const normalizeRequests = requests =>
  requests.reduce((acc, request, idx) => {
    // Get all requests after this one in the queue.
    const newerRequests = requests.slice(idx + 1);
    // Get the function to normalize this request.
    const normalizeFunc =
      Interceptors?.[request.interceptorName]?.normalizeFunc;
    // Get the updated request
    const updated = normalizeFunc
      ? normalizeFunc(request, newerRequests)
      : request;
    // If the updated request is null, skip adding it to the queue.
    if (updated) acc.push(updated);
    return acc;
  }, []);

export const InterceptedRequestReducer = (
  state = InterceptedRequestInitialState,
  { type, payload }
) => {
  switch (type) {
    case ADD_INTERCEPTED_REQUEST: {
      const { request, variables } = payload;

      return {
        ...state,
        requests: normalizeRequests(
          [...state.requests, request],
          state.interceptors
        ),
        variablesByType: variables.reduce(
          (acc, { type, key, value = null }) => {
            if (!acc[type]) acc[type] = {};
            acc[type][key] = value;
            return acc;
          },
          state.variablesByType
        ),
      };
    }

    case ADD_INTERCEPTED_VARIABLES: {
      return updateVariables(state, [...payload]);
    }

    case SET_INTERCEPTED_REQUESTS: {
      return {
        ...state,
        requests: normalizeRequests([...payload, ...state.requests]),
      };
    }

    case SET_INTERCEPTORS: {
      return {
        ...state,
        interceptors: payload,
      };
    }

    case UPDATE_PROCESSED_REQUESTS: {
      return {
        ...state,
        requests: state.requests.filter(({ id }) => !payload.includes(id)),
      };
    }

    // CAPTURE ALL SIGN IN EVENTS AND SAVE THE USER/CART ID
    case AUTH_USER__LOGIN_FULFILLED: {
      const key = payload?.data?.login ? 'login' : 'impersonate';
      const {
        data: {
          [key]: { user = null },
        },
      } = payload;

      if (!user) return state;

      return updateVariables(state, [
        { type: 'user', key: 'guest', value: user.id },
      ]);
    }

    case APP__START_FULFILLED: {
      if (!payload?.data?.cart) return state;

      const {
        data: { cart = null },
      } = payload;

      return updateVariables(state, [
        { type: 'user', key: 'guest', value: cart.userId },
        { type: 'cart', key: 'guest', value: cart.id },
      ]);
    }

    case AUTH_USER__CAPTURE_FULLFILLED: {
      const {
        data: {
          capture: { user = null },
        },
      } = payload;

      if (!user) return state;

      return updateVariables(state, [
        { type: 'user', key: 'guest', value: user.id },
      ]);
    }

    case AUTH_USER__REGISTER_FULFILLED: {
      const {
        data: {
          register: { user = null },
        },
      } = payload;

      if (!user) return state;

      return updateVariables(state, [
        { type: 'user', key: 'guest', value: user.id },
      ]);
    }

    // When they logout, clear the variables.
    case AUTH_USER__LOGOUT: {
      return {
        ...state,
        variablesByType: {
          user: {
            guest: null,
          },
          cart: {
            guest: null,
          },
        },
      };
    }

    case CHECKOUT__GET_PAYMENT_INTENT:
    case CHECKOUT__PLACE_ORDER_GUEST: {
      const { status: resStatus, order } = payload;

      if (resStatus !== 'succeeded') return state;
      const updatedRequests = state.requests.filter(
        ({ args }) => !order?.products?.includes(args?.cartProduct?.product)
      );

      return {
        ...state,
        requests: updatedRequests,
      };
    }

    default:
      return state;
  }
};

export default InterceptedRequestReducer;
