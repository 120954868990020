import { PRODUCT__EDIT_GIFTCARD } from 'constants/actionTypes';
import {
  CART_ITEM_DELETE,
  CART_ITEM_UPDATE,
} from 'features/carts/action-creators';
import { v4 as uuid } from 'uuid';

export const GUEST_ADD_TO_CART_INTERCEPTOR = {
  // The function that intercepts the request
  interceptFunc: ({ store, query, variables }) => {
    const { cartProduct } = variables;
    const product = store?.products?.productsById?.[cartProduct?.product];

    if (!product) throw 'Product not found for guest add to cart';

    // Find the cart product id if we already have it in the cart
    const existingCartProduct = (
      store?.carts?.cartsById?.[cartProduct?.user]?.products ?? []
    ).find(({ giftcardMetadata, product: { id: productId } }) => {
      return (
        productId === cartProduct?.product &&
        JSON.stringify(giftcardMetadata) ===
          JSON.stringify(cartProduct.giftcardMetadata)
      );
    });

    const tempId = existingCartProduct?.id ?? uuid();

    const response = {
      id: tempId,
      product,
      quantity: cartProduct?.quantity ?? 1,
      giftcardMetadata:
        cartProduct?.giftcardMetadata ??
        existingCartProduct?.giftcardMetadata ??
        null,
      source: cartProduct?.source,
      created: new Date().toISOString(),
      cart: {
        user: {
          id: 'guest',
        },
      },
    };

    const request = {
      id: uuid(),
      query,
      type: CART_ITEM_UPDATE,
      interceptorName: 'GUEST_ADD_TO_CART_INTERCEPTOR',
      args: {
        cartProduct,
      },
      response,
      requestVariables: [
        {
          type: 'user',
          key: 'guest',
          arg: 'cartProduct.user',
        },
      ],
      responseVariables: [
        {
          type: 'cartProduct',
          key: tempId,
          arg: 'cartProduct.id',
        },
      ],
    };

    return {
      response,
      request,
      variables: [
        {
          type: 'cartProduct',
          key: tempId,
          value: null,
        },
      ],
    };
  },
  // The function that is called when the response is received
  replaceFunc: ({ store, response, request }) => {
    store.dispatch(dispatch =>
      dispatch({
        type: request.type,
        status: 'success',
        payload: response?.cartProduct,
      })
    );
  },
  // A function that is run to cleanup redundant requests (returns null if the request should be removed)
  normalizeFunc: (request, requests) =>
    requests.reduce((acc, curr) => {
      // If this request has already been marked to remove, skip the rest of the checks.
      if (!acc) {
        return acc;
      }

      // If there is a delete request for the cart item, we don't need this request
      if (
        curr.type === CART_ITEM_DELETE &&
        curr.args.cartProduct === request.response.id
      ) {
        return null;
      }

      // If there is a newer version of the same request, we don't need this request.
      if (
        curr.type === CART_ITEM_UPDATE &&
        curr.response.id === request.response.id
      ) {
        return null;
      }

      // If there is an edit gift card request for the same product, we want to update this request.
      if (
        curr.type === PRODUCT__EDIT_GIFTCARD &&
        curr.response.id === request.response.id
      ) {
        return {
          ...request,
          args: {
            ...request.args,
            cartProduct: {
              ...request.args.cartProduct,
              giftcardMetadata: { ...curr.args.giftcardMetadata },
              product: { ...curr.response.product },
            },
          },
          response: {
            ...request.response,
            giftcardMetadata: { ...curr.args.giftcardMetadata },
            product: { ...curr.response.product },
          },
        };
      }

      return acc;
    }, request),
};
